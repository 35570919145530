import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { graphql } from 'gatsby';

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import Banner from "../../components/banner";
import Section from "../../components/section";
import Image from "../../components/image";
import AdvTitle from '../../components/advTitle';
import ImagesWithListContainer from "../../components/images-with-list-container";
import LightboxContainer from "../../components/lightbox-container";
import Testimonial from "../../components/testimonial";
import AnyQuestions from "../../components/any-questions";
import params from "../../data/params";

import "../../styles/business-up.less";

const BusinessUp = (props) => {
    return <Layout businessUp>
        <SEO 
            title={props.intl.formatMessage({id: "BusinessUp - Tools for business creation and access to investment"})} 
            description={props.intl.formatMessage({id: "BusinessUp is Adventurees SaaS for easily creating fully configurable entrepreneurial environments and investment communities."})}
            location={props.location}
            image="/images/sharing/home-products.jpg"
        />

        <Banner 
            className="banner business-up"
            colSize='col-md-8 align-self-end'
            filename="investment-banner.png"
            title={props.intl.formatMessage({id: "BusinessUp<br />Build your own platform in 10 minutes"})}
            subtitle={props.intl.formatMessage({id: "Create your own online entrepreneurship platform with your brand and help your cients achieve success"})}
            dangerous={{title: true, subtitle: false}}
            button={{text: 'Try it for free', url: params.DO_CREATE_URL, note: "No credit card required"}}
        />

        <Section title={props.intl.formatMessage({id: "Functionalities"})}>
            <div className="container product business-up landing">
                <div className="row justify-content-around">
                    <div className="col-lg-6 align-self-center">
                        <Image filename="products-business-up-laptop.png" />
                        <div className="free-trial-seal desktop">
                            <div className="free-trial-text">
                                {props.intl.formatMessage({id: "Try it"})}
                                <span>{props.intl.formatMessage({id: "Free"})}</span>
                            </div>
                            <div className="free-trial-period">
                                <span>{props.intl.formatMessage({id: "ONLY PAY AT"})}</span>
                                {props.intl.formatMessage({id: "SUBSCRIPTION ACTIVATION"})}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 product-features">
                        <AdvTitle title={props.intl.formatMessage({id: "The platform includes"})} />
                        <ul>
                            {/*<li>{props.intl.formatMessage({id: "Own web"})}</li>*/}
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Usage of your own domain"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Brand Look&Feel"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Own data and contents"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Editable pages"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Reception and filtering projects"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Goldsmith® model and analysis KPIs"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Business document management"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Online mentoring space"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Business showcase"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Events and community"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "100% autonomous administration"})}</li>
                            <li><span><img className="feature-icon" src="/images/pricing/check-icon.png" /></span>{props.intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</li>
                        </ul>
                        <div className="form-trigger">
                            <p className="free-trial-trigger-text">{props.intl.formatMessage({id: "For 149€ per month"})}</p>
                            <div className="free-trial-seal mobile">
                                <div className="free-trial-text">
                                    {props.intl.formatMessage({id: "Try it"})}
                                    <span>{props.intl.formatMessage({id: "Free"})}</span>
                                </div>
                                <div className="free-trial-period">
                                    <span>{props.intl.formatMessage({id: "ONLY PAY AT"})}</span>
                                    {props.intl.formatMessage({id: "SUBSCRIPTION ACTIVATION"})}
                                </div>
                            </div>
                            
                            <a href={params.DO_CREATE_URL}>{props.intl.formatMessage({id: "Try it for free"})}</a>
                            <p className="note">{props.intl.formatMessage({id: 'No credit card required'})}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
        
        <Section title={props.intl.formatMessage({id: "How can you benefir from BusinessUp?"})} className="product-platform-types">
            <ImagesWithListContainer elements={props.data.allSegmentsListJson.edges} className="services" />
        </Section>
        
        { /*
            <Section title={props.intl.formatMessage({id: "Get to know the platform's insides"})} className="d-none d-xl-block">
                <FunctionalityShowcase />
            </Section>
        */ }

        {/*
            <Section title={props.intl.formatMessage({id: "Set up your platform in 10 minutes"})} className="setup-steps d-none d-lg-block">
                <ImageColumns columns={props.data.allSetupsJson.edges} className="purchase-steps" />
                <div className="form-trigger">
                    <p className="free-trial-trigger-text">{props.intl.formatMessage({id: "Start now your FREE TRIAL for 7 days"})}</p>
                    <a href={params.DO_CREATE_URL}>{props.intl.formatMessage({id: "Start now"})}</a>
                </div>
            </Section>
        */}

        <Section title={props.intl.formatMessage({id: "How do our clients use BusinessUp?"})}>
            <LightboxContainer columns={props.data.allBusinessupClientsJson.edges} className="clients-platforms" />
        </Section>

        <Section title={props.intl.formatMessage({id: "Testimonials"})} className="businessUp-testimmonials">
            <div className="row testimonials justify-content-center">
                    {props.data.allTestimonialsJson.edges.map((element, index) => {
                        return <Testimonial key={index} testimonial={element.node} />
                    })}
            </div>
        </Section>

        <Section className="gdpr-notice">
            <div className="row">
                <div className="col-md-2">
                    <img src="/images/common/gdpr-logo.png" alt="GDPR Logo"/>
                </div>
                <div className="col-md-10 align-self-center">
                    <p>
                        {props.intl.formatMessage({id: "At Adventurees we comply with the General Data Protection Regulation of the European Union. All the data you generate in your platform will be your property. See our "})} 
                        <Link to="/privacy-policy"> {props.intl.formatMessage({id: "privacy policy"})} </Link> {props.intl.formatMessage({id: "to know more"})}.
                    </p>
                </div>
            </div>
        </Section>

        <AnyQuestions />
    </Layout>
}

export default injectIntl(BusinessUp);

export const setup = graphql`
    query BusinessUpQuery
    {
        allSetupsJson {
            edges
            {
                node
                {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                }
            }
        },
        allHelpItemsJson {
            edges {
                node {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                    description
                }
            }
        }
        allBusinessupClientsJson {
            edges
            {
                node
                {
                    colSize
                    className
                    imageName
                    imagePath {
                        childImageSharp {
                            fluid {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    imageAlt
                    text
                    type
                    link
                }
            }
        }
        allSegmentsListJson
        {
            edges
            {
                node
                {
                    wideTitle
                    colSize
                    className
                    imageName
                    imagePath
                    imageAlt
                    title
                    list { text }
                }
            }
        }

        allTestimonialsJson(limit: 3)
        {
            edges
            {
                node
                {
                    author {name imagePath job company}
                    content
                }
            }
        }
    }
`;
